import React from 'react';
import { Link } from 'gatsby';
import data from 'data/Software/blog.json';
import Download from 'components/Saas/Download';

const Blog3 = () => {
  return (
    <>
    <Download />
    <section className="blog section-padding style-3" data-scroll-index="7">
       
      <div className="container">
     
        <div className="row gx-0 justify-content-between">
         
          <div className="col-lg-12">
            <div className="faq style-3">
              <div className="section-head style-3 text-uppercase mb-50">
                <h3>FAQS</h3>
              </div>
              <div className="accordion" id="accordionExample">
                {
                  data.faq3.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={`heading${index+1}`}>
                        <button className={`accordion-button ${index === 0 ? '':'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index+1}`} aria-expanded="true" aria-controls={`collapse${index+1}`}>
                          { item.question }
                        </button>
                      </h2>
                      <div id={`collapse${index+1}`} className={`accordion-collapse collapse ${index === 0 ? 'show':''}`} aria-labelledby={`heading${index+1}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          { item.answer }
                        </div>
                      </div>
                    </div>
                  ))
                }
               
              </div>
            </div>
          </div>
        </div>
       
      </div>
     
    </section>
    </>
    
  )
}

export default Blog3